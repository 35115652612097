<template>
  <v-container class="pa-0 app-fill-height mobile-container">
    <v-container tag="section" class="pa-0 mb-10 mobile-container">
      <v-responsive
        max-width="800px"
        class="mx-auto"
      >
          <v-card class="page-box-wrapper elevation-3">
            <div class="page-box-content">
              <h2 class="h2-green">
                Набор <br />
                "Лучшее от LR ALOE VIA"
              </h2>
              <div class="img-top-box">
                <div class="top-logo-box">
                  <img
                    src="/img/dermatest.png"
                    alt="Dermatest"
                    class="dermatest-top"
                  />
                  <img
                    src="/img/made-in-germany.png"
                    alt="Made in Germany"
                    class="germany-top"
                  />
                </div>
                <div class="text-center">
                  <img
                    src="/img/natural_beauty/beauty-start.jpg"
                    alt="Лучшее от LR ALOE VIA"
                    class="natural-beauty-set"
                  />
                </div>
              </div>
              <div class="box-border-wrapper mb-3">
                <div class="box-border-content">
                  <v-row class="row-desc">
                    <v-col cols="3" class="avatar-box">
                      <img
                        src="/img/natural_beauty/family.png"
                        alt=""
                        class="avatar-img family"
                      />
                    </v-col>
                    <v-col cols="6" class="set-box">
                      <img
                        src="/img/natural_beauty/for-family.jpg"
                        alt=""
                        class="family-set-img"
                      />
                    </v-col>
                    <v-col cols="3" class="avatar-box">
                      <img
                        src="/img/natural_beauty/for-all-family-icon.png"
                        alt=""
                        class="avatar-icon family-icon-img"
                      />
                      <p>Для всей семьи</p>
                    </v-col>
                  </v-row>
                  <v-row class="row-mobile">
                    <v-col cols="6" class="avatar-box">
                      <img
                        src="/img/natural_beauty/family.png"
                        alt=""
                        class="avatar-img family"
                      />
                    </v-col>
                    <v-col cols="6" class="avatar-box">
                      <img
                        src="/img/natural_beauty/for-all-family-icon.png"
                        alt=""
                        class="avatar-icon family-icon-img"
                      />
                      <p>Для всей семьи</p>
                    </v-col>
                    <v-col cols="12" class="set-box">
                      <img
                        src="/img/natural_beauty/for-family.jpg"
                        alt=""
                        class="family-set-img"
                      />
                    </v-col>
                  </v-row>
                  <v-row class="item-content">
                    <v-col cols="2" class="item-img-box">
                      <img
                        src="/img/products/face-mask.png"
                        alt=""
                        class="item-content-img face-mask"
                      />
                    </v-col>
                    <v-col cols="10" class="item-text-box">
                      <h3 class="h3-item-content">
                        Aloe Vera Освежающая экспресс-маска для лица
                      </h3>
                      <p class="p-item-content">
                        Маска в виде легкого крема дарит коже сияющий вид и
                        оставляет на ней ощущение свежести. Комбинация из геля
                        Алоэ Вера, экстракта шиповника, Aquaxyl и Aquapront™
                        увлажняет до 95% всего за 3 минуты* и улучшает
                        эластичность кожи.
                      </p>
                      <p class="p-item-content">
                        50% геля Алоэ Вера обеспечивает сухой и раздраженной
                        коже интенсивное увлажнение. Aquapront™ является
                        заполнителем морщин, который уменьшает поры и тем самым
                        улучшает состояние кожи. Нежное вещество Aquaxyl™
                        регулирует потерю влаги. Пантенол улучшает и повышает
                        способность кожи сохранять необходимое количество влаги.
                      </p>
                    </v-col>
                  </v-row>
                  <v-row class="item-content">
                    <v-col cols="2" class="item-img-box">
                      <img
                        src="/img/products/magic-bubble-mask.png"
                        alt=""
                        class="item-content-img magic-bubble-mask"
                      />
                    </v-col>
                    <v-col cols="10" class="item-text-box">
                      <h3 class="h3-item-content">
                        Aloe Vera Волшебная пузырьковая маска для лица
                      </h3>
                      <p class="p-item-content">
                        Очищающая пузырьковая детокс-маска для лица с глубоким
                        воздействием. Предотвращает накопление загрязняющих
                        частиц, увлажняет, кожа снова свежая и сияет красотой.
                      </p>
                      <p class="p-item-content">
                        <strong>Детокс:</strong> Экстракты имбиря и моринги
                        удаляют омертвевшие клетки кожи и нейтрализуют
                        негативное влияние окружающей среды, борясь, например, с
                        частицами пыли и свободными радикалами. Маска глубоко
                        очищает и предотвращает накопление загрязняющих частиц
                        благодаря эффекту лотоса (эффекту самоочищения).
                        Био-экстракт имбиря в составе помогает защитить кожу.
                      </p>
                      <p class="p-item-content">
                        <strong>Увлажнение:</strong> Алоэ Вера интенсивно
                        увлажняет и способствует восстановлению кожи.
                      </p>
                      <p class="p-item-content">
                        <strong>Активатор пузырей:</strong> Активатор пузырей
                        увеличивает подачу кислорода и создает пузырьки, которые
                        усиливают очищающий и увлажняющий эффект маски. Активные
                        компоненты проникают глубоко в поры, загрязнения легче
                        удаляются, а влага проникает глубже.
                      </p>
                    </v-col>
                  </v-row>
                  <v-row class="item-content">
                    <v-col cols="2" class="item-img-box">
                      <img
                        src="/img/products/day-cream.png"
                        alt=""
                        class="item-content-img day-cream"
                      />
                    </v-col>
                    <v-col cols="10" class="item-text-box">
                      <h3 class="h3-item-content">
                        Aloe Vera Активизирующий дневной крем
                      </h3>
                      <p class="p-item-content">
                        Дневной крем, содержащий ценный органический экстракт
                        оливок, масло виноградных косточек и 50% алоэ вера,
                        освежает кожу и придает коже эластичность.
                      </p>
                      <p class="p-item-content">
                        Активный дневной крем восстанавливает естественную
                        красоту кожи, устраняя раздражение и сухость кожи.
                        Прекрасно увлажняет и защищает кожу.
                      </p>
                    </v-col>
                  </v-row>
                  <v-row class="item-content">
                    <v-col cols="2" class="item-img-box">
                      <img
                        src="/img/products/hand-cream.png"
                        alt=""
                        class="item-content-img hand-cream"
                      />
                    </v-col>
                    <v-col cols="10" class="item-text-box">
                      <h3 class="h3-item-content">
                        Aloe Vera Смягчающий крем для рук
                      </h3>
                      <p class="p-item-content">
                        Быстро впитывающийся крем для рук. Содержит 35% геля
                        Алоэ Вера и экстракт календулы. Питает и увлажняет кожу,
                        придавая ей мягкость и нежность
                      </p>
                      <p class="p-item-content">
                        Насыщает кожу необходимым количеством влаги. Крем быстро
                        впитывается и обеспечивает уход и защиту.
                      </p>
                    </v-col>
                  </v-row>
                  <v-row class="item-content">
                    <v-col cols="2" class="item-img-box">
                      <img
                        src="/img/products/deo-roll-on.png"
                        alt=""
                        class="item-content-img deo-roll-on"
                      />
                    </v-col>
                    <v-col cols="10" class="item-text-box">
                      <h3 class="h3-item-content">
                        Aloe Vera Шариковый дезодорант
                      </h3>
                      <p class="p-item-content">
                        Мягкая антиперспирантная защита. Увлажняющий гель алоэ
                        вера и экстракт органического хлопка обеспечивают
                        ощущение безопасности и свежести в течение всего дня.
                        Безалкогольная нежная формула с тонким ароматом
                        ненавязчиво освежает.
                      </p>
                    </v-col>
                  </v-row>
                  <v-row class="item-content">
                    <v-col cols="2" class="item-img-box">
                      <img
                        src="/img/products/hand-soap.png"
                        alt=""
                        class="item-content-img hand-soap"
                      />
                    </v-col>
                    <v-col cols="10" class="item-text-box">
                      <h3 class="h3-item-content">Aloe Vera Мыло для рук</h3>
                      <p class="p-item-content">
                        Мягкое очищающее мыло для рук Aloe Via очень мягко
                        очищает и одновременно питает ваши руки. Содержит 38%
                        алоэ вера и ценный экстракт календулы, увлажняет кожу.
                      </p>
                    </v-col>
                  </v-row>
                  <v-row class="item-content">
                    <v-col cols="2" class="item-img-box">
                      <img
                        src="/img/products/tooth-gel.png"
                        alt=""
                        class="item-content-img tooth-gel"
                      />
                    </v-col>
                    <v-col cols="10" class="item-text-box">
                      <h3 class="h3-item-content">
                        Aloe Vera Зубная паста-гель
                      </h3>
                      <p class="p-item-content">
                        Зубная паста-гель способствует здоровью десен, благодаря
                        экстракту эхинацеи и 43% алоэ вера. Тщательно очищает
                        эмаль зубов и десны, способствует сиянию зубов и свежему
                        дыханию.
                      </p>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <div class="box-border-wrapper">
                <div class="box-border-content">
                  <v-row class="row-desc">
                    <v-col cols="3" class="avatar-box">
                      <img
                        src="/img/natural_beauty/emergency-dad-son.jpg"
                        alt=""
                        class="avatar-img emergency-dad-son"
                      />
                    </v-col>
                    <v-col cols="6" class="set-box">
                      <img
                        src="/img/natural_beauty/emergency-thermo.jpg"
                        alt=""
                        class="emergency-set-img"
                      />
                    </v-col>
                    <v-col cols="3" class="avatar-box">
                      <img
                        src="/img/natural_beauty/emergency-icon.png"
                        alt=""
                        class="avatar-icon emergency-icon-img"
                      />
                      <p>Скорая помощь</p>
                    </v-col>
                  </v-row>
                  <v-row class="row-mobile">
                    <v-col cols="6" class="avatar-box">
                      <img
                        src="/img/natural_beauty/emergency-dad-son.jpg"
                        alt=""
                        class="avatar-img emergency-dad-son"
                      />
                    </v-col>
                    <v-col cols="6" class="avatar-box">
                      <img
                        src="/img/natural_beauty/emergency-icon.png"
                        alt=""
                        class="avatar-icon emergency-icon-img"
                      />
                      <p>Скорая помощь</p>
                    </v-col>
                    <v-col cols="12" class="set-box">
                      <img
                        src="/img/natural_beauty/emergency-thermo.jpg"
                        alt=""
                        class="emergency-set-img"
                      />
                    </v-col>
                  </v-row>
                  <v-row class="item-content">
                    <v-col cols="2" class="item-img-box">
                      <img
                        src="/img/products/emergency-spray-200.jpg"
                        alt=""
                        class="item-content-img emergency-spray"
                      />
                    </v-col>
                    <v-col cols="10" class="item-text-box">
                      <h3 class="h3-item-content">
                        Aloe Vera Спрей «Скорая помощь»
                      </h3>
                      <p class="p-item-content">
                        Спрей Скорая помощь Идеальный уход SOS с использованием
                        алоэ вера. Наносится на поврежденную кожу в виде
                        защитной пленки. Высокое содержание алоэ вера (83%) и
                        двенадцать проверенных временем растительных экстрактов
                        успокаивают, защищают и увлажняют кожу.
                      </p>
                    </v-col>
                  </v-row>
                  <v-row class="item-content">
                    <v-col cols="2" class="item-img-box">
                      <img
                        src="/img/products/propolis.png"
                        alt=""
                        class="item-content-img propolis"
                      />
                    </v-col>
                    <v-col cols="10" class="item-text-box">
                      <h3 class="h3-item-content">
                        Aloe Vera Защитный крем с прополисом
                      </h3>
                      <p class="p-item-content">
                        Этот насыщенный питательный крем, содержащий 79% геля
                        алоэ вера и экстракт прополиса, особенно подходит для
                        ухода за сухой и чувствительной кожей. Он укрепляет
                        кожный барьер и создает идеальную защиту поврежденной
                        кожи, увлажняющий эффект алоэ вера придают коже
                        ухоженный и здоровый вид.
                      </p>
                    </v-col>
                  </v-row>
                  <v-row class="item-content">
                    <v-col cols="2" class="item-img-box">
                      <img
                        src="/img/products/concentrated.png"
                        alt=""
                        class="item-content-img concentrated"
                      />
                    </v-col>
                    <v-col cols="10" class="item-text-box">
                      <h3 class="h3-item-content">
                        Aloe Vera Увлажняющий гель-концентрат
                      </h3>
                      <p class="p-item-content">
                        Увлажняющий гель-концентрат, содержащий 90% чистого геля
                        из листьев алоэ вера, мгновенно охлаждает и увлажняет
                        кожу, способствуя регенерации. Алоэ вера придает коже
                        более свежий и эластичный вид. Кожа сразу же становится
                        интенсивно ухоженной. Идеальное сочетание со спреем
                        Скорая Помощь.
                      </p>
                    </v-col>
                  </v-row>
                  <v-row class="item-content">
                    <v-col cols="2" class="item-img-box">
                      <img
                        src="/img/products/thermo-lotion.png"
                        alt=""
                        class="item-content-img thermo-lotion"
                      />
                    </v-col>
                    <v-col cols="10" class="item-text-box">
                      <h3 class="h3-item-content">
                        Aloe Vera Расслабляющий термолосьон
                      </h3>
                      <p class="p-item-content">
                        Расслабляет напряженные мышцы, а натуральные масла в его
                        составе обеспечивают глубокий уход за кожей. Содержит
                        45% геля Алоэ Вера и натуральные эфирные масла: оливкое,
                        кунжутное, масло жожоба, масло абрикосовых косточек,
                        которые делают кожу мягкой и бархатистой. Тепловой
                        эффект улучшает кровоснабжение.
                      </p>
                      <p class="p-item-content">
                        Легкая текстура лосьона идеально подходит для массажа.
                        Натуральные масла сначала оказывают освежающее действие
                        на кожу. На втором этапе появляется согревающий эффект,
                        что способствует релаксации. Эвкалиптовое масло
                        стимулирует приток крови к тканям и вызывает приятный
                        охлаждающий эффект на коже.
                      </p>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <div class="text-center mt-10" id="contacts">
                <h3 class="h3-contacts">
                  За подробной консультацией по набору напишите мне
                </h3>
                <v-icon
                  icon="mdi-chevron-triple-down"
                  size="x-large"
                  color="#94b894"
                  class="mt-5"
                ></v-icon>
                <div class="mt-12 mb-7">
                  <div class="d-flex grow flex-wrap">
                        <div
                            class="contact-avatar v-avatar mx-auto v-card--material__avatar elevation-6 grey"
                            style="height: 128px; min-width: 128px; width: 128px"
                        >
                            <div class="v-image v-responsive theme--light">
                                <img v-if="userData.avatar"
                                     :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                     alt=""
                                >
                                <div class="v-responsive__content" style="width: 250px"></div>
                            </div>
                        </div>

                        <v-card-text class="text-center">
                            <h4 class="display-2 font-weight-light mb-3 black--text">
                                {{ userData.name }} {{ userData.last_name }}
                            </h4>
                        </v-card-text>
                    </div>
                    <v-row>
                        <v-col class="text-center">
                            <div class="input-group mb-3">
                                <v-tooltip
                                    top
                                    v-if="userData.phone_whatsapp"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <a
                                            style="text-decoration: none"
                                            :href="`https://wa.me/${userData.phone_whatsapp}`"
                                            target="_blank"
                                        >
                                            <v-btn
                                                fab
                                                dark
                                                small
                                                class="mr-4"
                                                color="success"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                <v-icon>mdi-whatsapp</v-icon>
                                            </v-btn>
                                        </a>
                                    </template>
                                    <span>Связаться в WhatsApp</span>
                                </v-tooltip>

                                <v-tooltip
                                    top
                                    v-if="userData.telegram"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <a
                                            style="text-decoration: none"
                                            :href="`${userData.telegram}`"
                                            target="_blank"
                                        >
                                            <v-btn
                                                fab
                                                dark
                                                small
                                                class="mr-4"
                                                color="blue"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                <v-icon>fab fa-telegram-plane</v-icon>
                                            </v-btn>
                                        </a>
                                    </template>
                                    <span>Связаться в Telegram</span>
                                </v-tooltip>
                            </div>
                        </v-col>
                    </v-row>
                </div>
              </div>
            </div>
          </v-card>
      </v-responsive>
    </v-container>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "NaturalBeauty",
    data: () => ({
        cookieShow: true,
    }),
    metaInfo: {
        title: 'Набор для всей семьи Естественная красота от LR Health & Beauty',
        meta: [
            { vmid: 'description', property: 'description', content: 'Набор для всей семьи Естественная красота от LR Health & Beauty - продукты ежедневного использования со скидкой 53%' },
            { vmid: 'og:title', property: 'og:title', content: 'Набор для всей семьи Естественная красота от LR Health & Beauty' },
            { vmid: 'og:description', property: 'og:description', content: 'Набор для всей семьи Естественная красота от LR Health & Beauty - продукты ежедневного использования со скидкой 53%' },
            { vmid: 'og:image', property: 'og:image', content: '' },
        ],
    },
    created() {
        if (!this.$route.query.p) {
            if (this.$cookies.isKey('lifeplus_partner')) {
                const partnerNum = this.$cookies.get('lifeplus_partner')
                this.$router.push ({name: 'NaturalBeauty', query: { p: partnerNum }})
            }
        } else {
            this.$cookies.set('lifeplus_partner', this.$route.query.p, '6m')
        }

        if (this.$cookies.isKey('lifeplus_cookie')) {
            this.cookieShow = false
        }
    },
    computed: {
        ...mapGetters({
            userData: 'user/getPartnerAccess'
        }),
    },
    methods: {
        ...mapActions({
            addNotification: 'application/addNotification',
            showPartnerAccess: 'user/showPartnerAccess',
        }),
        cookieAccept() {
            this.cookieShow = false
            this.$cookies.set('lifeplus_cookie', 'accept', '12m')
        },
    },
    mounted () {
        this.showPartnerAccess(this.$route.query.p)
    },
}
</script>

<style lang="scss" scoped>
@font-face {
  font-family: raleway-b;
  src: url("/fonts/raleway-black.woff2");
  font-weight: 300;
}
.app-fill-height {
  font-family: Roboto,sans-serif;
  font-size: 16px !important;
}
ul li {
  margin-bottom: 5px;
}
.contact-avatar {
  top:-30px;
  bottom:0;
}
.page-box-wrapper {
  background: linear-gradient(to right, #99cb9c, #a0c9c8);
  padding: 10px;
}
.page-box-content {
  background-color: #fff;
  padding: 10px 5px 20px;
  border-radius: 3px;
}
.h2-green {
  font-family: raleway-b;
  font-size: calc(24px + (36 - 24) * ((100vw - 320px) / (800 - 320)));
  text-transform: uppercase;
  text-align: center;
  color: #94b894;
}
.box-border-wrapper {
  background: linear-gradient(to right, #99cb9c, #a0c9c8);
  padding: 2px;
  border-radius: 5px;
}
.box-border-content {
  background-color: #fff;
  border-radius: 3px;
  padding: 10px;
}
.img-top-box {
  margin: 20px 0;
}
.top-logo-box {
  position: relative;
  height: calc(70px + (100 - 70) * ((100vw - 320px) / (800 - 320)));
}
.dermatest-top {
  position: absolute;
  width: calc(130px + (150 - 130) * ((100vw - 320px) / (800 - 320)));
  top: calc(5% + (10 - 5) * ((100vw - 320px) / (800 - 320)));
  left: calc(5% + (10 - 5) * ((100vw - 320px) / (800 - 320)));
}
.germany-top {
  position: absolute;
  width: calc(40px + (70 - 40) * ((100vw - 320px) / (800 - 320)));
  right: calc(10% + (15 - 10) * ((100vw - 320px) / (800 - 320)));
}
.natural-beauty-set {
  max-width: 700px;
  width: 100%;
}
.avatar-img {
  width: 100%;
  border-radius: 50%;
  border: 3px solid #94b894;
}
.set-box {
  text-align: center;
  align-self: flex-end;
}
.avatar-box {
  text-align: center;
  align-self: center;
}
.set-box p,
.avatar-box p {
  font-weight: 600;
  text-transform: uppercase;
  font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (800 - 320)));
}
.cleansing-img {
  width: 100%;
}
.care-img {
  width: 70%;
}
.hair-care-img {
  width: 70%;
}
.family-set-img {
  width: 90%;
}
.family-icon-img,
.emergency-icon-img {
  width: 60%;
}
.emergency-set-img {
  width: 65%;
}
.row-mobile {
  display: none;
}
.avatar-box {
  text-align: center;
  align-self: center;
}
.avatar-icon {
  border: 3px solid #94b894;
  border-radius: 50%;
  padding: 10px;
}
.item-content {
  margin-top: 20px;
}
.item-content-img {
  width: 100%;
}
.item-content-img.face-mask {
  width: 80%;
}
.item-content-img.cleansing-foam {
  width: 60%;
}
.item-content-img.magic-bubble-mask {
  width: 55%;
}
.item-content-img.hair-shampoo {
  width: 55%;
}
.item-content-img.hand-cream {
  width: 60%;
}
.item-content-img.deo-roll-on {
  width: 50%;
}
.item-content-img.hand-soap {
  width: 60%;
}
.item-content-img.tooth-gel {
  width: 45%;
}
.item-content-img.emergency-spray {
  width: 60%;
}
.item-content-img.propolis {
  width: 65%;
}
.item-content-img.concentrated {
  width: 65%;
}
.item-content-img.thermo-lotion {
  width: 65%;
}
.item-img-box {
  align-self: center;
  text-align: center;
  position: relative;
}
.item-text-box {
  align-self: center;
}
.h3-item-content {
  font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (800 - 320)));
}
.p-item-content {
  font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (800 - 320)));
  margin-top: 10px;
}
.span-x2 {
  position: absolute;
  background-color: #4aa4d4;
  color: #fff;
  padding: 2px 5px 3px;
  border-radius: 50%;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 1px;
  top: -3px;
  right: 10px;
}
.h3-contacts {
  text-transform: uppercase;
  color: #333;
}
@media (min-width: 800px) {
  .h2-green {
    font-size: 36px;
  }
  .dermatest-top {
    width: 170px;
  }
  .germany-top {
    width: 70px;
  }
  .set-box p {
    font-size: 16px;
  }
}
@media (max-width: 600px) {
  .avatar-img {
    width: 80%;
  }
  .cleansing-img {
    width: 80%;
  }
  .care-img {
    width: 60%;
  }
  .hair-care-img {
    width: 60%;
  }
  .item-content-img.face-mask,
  .item-content-img.cleansing-foam,
  .item-content-img.magic-bubble-mask,
  .item-content-img.hair-shampoo,
  .item-content-img.hand-cream,
  .item-content-img.deo-roll-on,
  .item-content-img.hand-soap,
  .item-content-img.emergency-spray,
  .item-content-img.propolis,
  .item-content-img.concentrated,
  .item-content-img.thermo-lotion {
    width: 100%;
  }
  .item-content-img.tooth-gel {
    width: 80%;
  }
  .row-desc {
    display: none;
  }
  .row-mobile {
    display: flex;
    margin-top: 0;
  }
  .family-icon-img,
  .emergency-icon-img {
    width: 50%;
  }
  .family-set-img {
    width: 70%;
  }
  .emergency-set-img {
    width: 60%;
  }
}
</style>